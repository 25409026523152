<template>
  <main class="resetPassword-page flex justify-center items-center h-screen">
    <div
      class="xl:w-2/3 lg:w-2/3 md:w-3/4 sm:w-11/12 sm:mx-4 rounded shadow-card grid xl:grid-cols-2 lg:grid-cols-2"
    >
     <div class="image-and-logo bg-light-gray">
        <div class="logo-images flex justify-center items-center my-4 lg:my-8">
          <img
            src="@/assets/images/main-logo.png"
            alt="Xlinx Logo"
            class="mr-2 align-top hidden lg:block"
          />
          <img
            src="@/assets/images/header-logo.png"
            alt="Xlinx Logo"
            class="mr-2 align-top block lg:hidden"
          />
        </div>

        <div class="register-icon mx-auto mb-4 hidden lg:block">
          <img
            src="@/assets/images/login-image.png"
            alt="Register Image"
            class="w-full h-full"
          />
        </div>
      </div>

      <div class="font-sans p-8 text-left">
        <div class="mb-8">
          <h4 class="text-2xl font-bold">
            {{ $t('notify.pages.resetPassword.resetHeading') }}
          </h4>
          <p class="mt-2 text-dark-gray">
            {{ $t('notify.pages.resetPassword.label') }}.
          </p>
        </div>

        <form
          action="#"
          @submit.prevent="submit"
          @keyup="removeError($event.target.name)"
          @change="removeError($event.target.name)"
        >
          <div class="form-group pb-3">
            <input
              type="password"
              name="password"
              placeholder="Password"
              class="block border-0 border-b-half w-full focus:outline-none"
              :class="[
                !!errors.password
                  ? ['border-red-900 placeholder-red-900']
                  : ['border-light-green placeholder-gray-500']
              ]"
              v-model="form.password"
            />

            <label
              for="name"
              class="text-xs"
              :class="!!errors.password ? 'text-red-900' : 'text-gray-500'"
            >
              {{ !!errors.password ? errors.password[0] : 'Your new Password' }}
            </label>
          </div>

          <div class="form-group pb-3">
            <input
              type="password"
              name="password_confirmation"
              placeholder="Re type your password"
              class="block border-0 border-b-half w-full focus:outline-none"
              :class="[
                !!errors.password_confirmation
                  ? ['border-red-900 placeholder-red-900']
                  : ['border-light-green placeholder-gray-500']
              ]"
              v-model="form.password_confirmation"
            />

            <label
              for="name"
              class="text-xs"
              :class="
                !!errors.password_confirmation
                  ? 'text-red-900'
                  : 'text-gray-500'
              "
            >
              {{
                !!errors.password_confirmation
                  ? errors.password_confirmation[0]
                  : 'Confirm Password'
              }}
            </label>
          </div>

          <div class="flex justify-between mt-10">
            <router-link
              :to="{ name: 'login' }"
              class="bg-white text-dark-green border border-dark-green hover:bg-dark-green hover:text-white p-1 px-10 text-center transition ease-in duration-150"
            >
              Back to login
            </router-link>

            <button
              class="bg-dark-green text-gray-200 p-1 px-10 text-center transition ease-in duration-100 hover:opacity-90"
              :disabled="loading"
            >
              Submit
              <font-awesome-icon
                icon="circle-notch"
                class="fa-spin"
                v-show="loading"
              />
            </button>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'forgotPassword'
}
</script>

<script setup>
import { reactive, ref, computed, onMounted, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import validator from '@/composables/auth/validator'
import usePasswords from '@/composables/auth/usePasswords'

defineProps({
  token: String
})

const route = useRoute()
const router = useRouter()

const form = reactive({
  password: '',
  password_confirmation: ''
})

const notify = inject('notify')
const t = inject('t')

const loading = ref(false)
const { removeError, clearErrors, validate, errors } = validator()
const { resetPassword } = usePasswords()

const hasErrors = computed(() => {
  return Object.values(errors.value).length
})

//const token = route.query.token;
const token = route.params.token

const submit = async () => {
  validate({
    data: form,
    rules: {
      password: ['required', 'between:8-20'],
      password_confirmation: ['required', 'match:password']
    }
  })

  if (hasErrors.value) {
    notify({
      message: t('notify.modules.components.common.fix_errors'),
      type: 'danger'
    })
    return
  }

  loading.value = true

  try {
    const response = await resetPassword({
      ...form,
      token,
      email: route.query.email || ''
    })
    loading.value = false
    if (response == 'This password reset token is invalid.') {
      notify({
        message: t('validation.main.pages.resetPassword.token_error'),
        type: 'danger'
      })
    } else {
      clearErrors()
      resetForm()
      router.push({
        name: 'login',
        query: {
          success: true
        }
      })
    }
  } catch (e) {
    loading.value = false
    notify({
      message: t('validation.main.pages.resetPassword.error_msg'),
      type: 'danger'
    })
  }
}

const resetForm = () => {
  form.email = ''
  form.password = ''
  form.password_confirmation = ''
}

const redirectIfTokenNotExists = function () {
  if (token == '' || token == null) {
    router.replace({ name: 'login' })
  } else {
    localStorage.setItem('token', token)
  }
}

onMounted(() => {
  redirectIfTokenNotExists()
})
</script>

<style scoped lang="scss">
.logo-images {
  .image2 {
    width: 200px;
    height: 30px;
    @media (max-width: 1200px) {
      width: 170px;
    }
    @media (max-width: 900px) {
      width: 150px;
    }
    @media (max-width: 600px) {
      width: 120px;
    }
  }
  .image3 {
    width: 103px;
    height: 30px;
    @media (max-width: 1200px) {
      width: 90px;
    }
    @media (max-width: 900px) {
      width: 80px;
    }
    @media (max-width: 600px) {
      width: 70px;
    }
  }
}

.register-icon {
  width: 300px;
  height: 300px;
  @media (max-width: 1200px) {
    width: 250px;
    height: 250px;
  }
  @media (max-width: 600px) {
    width: 200px;
    height: 200px;
  }
}
.border-b-half {
  border-bottom-width: 0.5px;
}
</style>
